import axios from "axios";
import TokenService from "./token.service";

class AuthService {
  login({ email, password }) {
    return axios
      .post("https://api.fepatexiot.com/v1/auth/login", {
        email,
        password
      })
      .then((response) => {
        if (response.data.user) {
          TokenService.setUser(response.data.user);
          TokenService.setAccessToken(response.data.tokens.access);
          TokenService.setRefreshToken(response.data.tokens.refresh);
        }

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();

  }

  register({ username, email, password }) {
    return axios.post("/auth/signup", {
      username,
      email,
      password
    });
  }
}

export default new AuthService();
