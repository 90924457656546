import moment from 'moment';


export function getAuthToken() {
    return localStorage.getItem('access')    
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    return !!authToken && !isTokenExpired(authToken)
}

export function getUserInfo() {
    if (isLoggedIn()) {
        return JSON.parse(localStorage.getItem('user'))
    }
}

export function tokenRefreshNeeded()
{
    let authToken = getAuthToken()
    return isExpiryNear(authToken)
}

function getTokenExpirationDate(encodedToken) {
    let token = JSON.parse(encodedToken)
    if (!token.expires) {
        return null
    }
      
    return token.expires
}
  
function isTokenExpired(token) {
    let expirationDate = getTokenExpirationDate(token)
    return expirationDate < new Date().toISOString()
}

function isExpiryNear(token) {
    
    let expirationDate = getTokenExpirationDate(token)
    const newdate = new Date();
    const date2 = moment(newdate).add(5, 'm').toDate()
    return expirationDate < date2.toISOString()
}