
import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import { isLoggedIn  } from './common/auth'
// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const CustomersOverview = () => import("./components/CustomersOverview.vue")
const CustomersView = () => import("./components/CustomersView.vue")
const Dashboard = () => import("./components/Dashboard.vue")
const DevicesOverview = () => import("./components/DevicesOverview.vue")
const DevicesView = () => import("./components/DevicesView.vue")
const UsersOverview = () => import("./components/UsersOverview.vue")
const UsersView = () => import("./components/UsersView.vue")
const UsersPassword = () => import("./components/UsersPassword.vue")
const UsersCreate = () => import("./components/UsersCreate.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/customers",
    name: "customers",
    component: CustomersOverview,
  },
  {
    path: "/customers/:id",
    name: "customers view",
    component: CustomersView,
  },
  {
    path: "/devices",
    name: "devices",
    component: DevicesOverview,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/devices/:id",
    name: "device view",
    component: DevicesView,
  },
  {
    path: "/users",
    name: "users",
    component: UsersOverview,
  },
  {
    path: "/users/:id",
    name: "users view",
    component: UsersView,
  },
  {
    path: "/users/password/:id",
    name: "users password",
    component: UsersPassword,
  },
  {
    path: "/users/create",
    name: "users create",
    component: UsersCreate,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

 router.beforeEach((to, from, next) => {
   const publicPages = ['/login', '/register'];
   const authRequired = !publicPages.includes(to.path);
//   // trying to access a restricted page + not logged in
//   // redirect to login page
   if (authRequired && !isLoggedIn()) {
     next('/login');
   } else {
     next();
   }
 });

export default router;