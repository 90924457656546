<template>
  
  <header style="background-color: #E7ECEF" class="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow">


    <div class="container d-flex align-items-left">
      <a class="navbar-brand" href="#"><img src="../assets/images/fepatex-logo.png" alt="logo" width="80"></a>
      <ul class="uk-navbar-nav uk-hidden-small">

        <li class="uk">
          <router-link to="/home" class="nav-link">Dashboard</router-link>
        </li>
        <li class="uk">
          <router-link to="/devices" class="nav-link">Devices</router-link>
        </li>
        <li class="uk">
          <a @click.prevent="logOut" class="nav-link">Logout</a>
        </li>
      </ul>
    </div>
  </header>

</template>
<script>
import EventBus from "../common/EventBus";
import { getUserInfo } from '../common/auth'
export default {
  name: 'Header',
  data() {
    return {
      content: "Header",
      currentUser: getUserInfo().name,
    };
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeUnmount() {
    EventBus.remove("logout");
  }
}

</script>