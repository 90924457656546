class TokenService {
  getLocalRefreshToken() {
    const token = JSON.parse(localStorage.getItem("refresh"));
    return token.token;
  }

  getLocalAccessToken() {
    const token = JSON.parse(localStorage.getItem("access"));
    return token.token;
  }

  updateLocalAccessToken(token) {
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("token2", JSON.stringify(token));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  setAccessToken(token) {
    localStorage.setItem("access", JSON.stringify(token));
  }

  setRefreshToken(token) {
    localStorage.setItem("refresh", JSON.stringify(token));
  }

  removeUser() {
    localStorage.removeItem("user");
    localStorage.removeItem("refresh");
    localStorage.removeItem("access");
  }
}

export default new TokenService();
