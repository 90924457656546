
<template>
  <Layout>
    <h1 class="uk-heading-small">Dashboard</h1>
    <div class="uk-child-width-1-3@m uk-grid-small uk-grid-match" uk-grid>
      <div>
        <div class="uk-card uk-card-default uk-card-body">
          <h3 class="uk-card-title"># of Machines</h3>
          <p>{{ devices.length }}</p>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-default uk-card-body">
          <h3 class="uk-card-title"># of Online</h3>
          <p>{{ deviceonline }}</p>
        </div>
      </div>
      <div>
        <div class="uk-card uk-card-default uk-card-body">
          <h3 class="uk-card-title"># of Maintenance</h3>
          <p>{{ devicemaintenance }}</p>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import DeviceService from "../services/device.service";
import EventBus from "../common/EventBus";
import Layout from '../layouts/private.vue'
export default {

  name: "Home",
  components: { Layout },
  data() {
    return {
      content: "Hello",
      devices: "",
      deviceonline: "",
      devicemaintenance: "",

    };
  },
  created() {
    this.devicesRerender(),
      this.timer = setInterval(this.devicesRerender, 3000);
  },

  methods: {
    devicesRerender() {
      DeviceService.getDevices().then(
        (response) => {
          this.devices = response.data.results;
          this.deviceonline = response.data.results.filter((devices) => {return devices.connected.includes('online')}).length ;
          this.devicemaintenance = response.data.results.filter((devices) => {return devices.status.includes('maintenance')}).length ;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response && error.response.status === 403) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    }
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  }
};
</script>
