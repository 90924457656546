<template>

    <Header />


    <div style="margin-top:10px" class="uk-container uk-container-center ">
    <slot />
    </div>
</template>

<script>
import Header from './header.vue'


export default {
  name: 'Layout',
  components: {
    Header,

  },

}

</script>