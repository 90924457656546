  
import api from './api';
class DeviceService {

  getDevices() {
    return api.get('/devices');
  }

  getDevice(id) {
    const deviceconstuct = '/devices/'+id;
    return api.get(deviceconstuct);
  }
  updateDevice(id, deviceBody) {
    const deviceconstuct = '/devices/'+id;
    return api.patch(deviceconstuct,deviceBody);
  }
  getDeviceTelemetry(id,start,end) {
    const deviceTelemetryconstuct = '/devices/telemetry/'+id+'?startdate='+start+'&enddate='+end;
    return api.get(deviceTelemetryconstuct);
  }
  getDeviceTelemetryDay(id) {
    const deviceTelemetryDayconstuct = '/devices/telemetry/day/'+id;
    return api.get(deviceTelemetryDayconstuct);
  }
  getDeviceStatus(id,start,end) {
    const deviceStatusconstuct = '/devices/status/'+id+'?startdate='+start+'&enddate='+end;
    return api.get(deviceStatusconstuct);
  }
}

export default new DeviceService();
